import { useEffect } from "react";

interface ModalProps {
  onDreamExampleClick: (text: string) => void;
}

const Modal: React.FC<ModalProps> = ({ onDreamExampleClick }) => {
  useEffect(() => {
    const modalbg = document.getElementById("modal-bg");
    const modalSwitch = document.getElementById("modal-switch");
    const modalBox = document.getElementById("modal-box");
    const modalClose = document.getElementById("modal-close");
    const modalCloseIcon = document.getElementById("modal-close-icon");
    const dreamExamples = document.querySelectorAll(".dream-example");

    modalbg?.addEventListener("click", () => {
      modalBox?.classList.add("hidden");
      modalbg?.classList.add("hidden");
    });

    modalSwitch?.addEventListener("click", () => {
      modalBox?.classList.remove("hidden");
      modalbg?.classList.remove("hidden");
    });

    modalClose?.addEventListener("click", () => {
      modalBox?.classList.remove("hidden");
      modalbg?.classList.remove("hidden");
    });

    modalCloseIcon?.addEventListener("click", () => {
      modalBox?.classList.add("hidden");
      modalbg?.classList.add("hidden");
    });

    // dreamExample?.addEventListener("click", () => {
    //   modalBox?.classList.add("hidden");
    //   modalbg?.classList.add("hidden");
    // });

    dreamExamples.forEach((dreamExample) => {
      dreamExample.addEventListener("click", () => {
        modalBox?.classList.add("hidden");
        modalbg?.classList.add("hidden");
      });
    });
  }, []);

  return (
    <>
      <div className="absolute h-full w-full">
        <div
          id="modal-bg"
          className="absolute left-0 top-0 z-50 hidden h-full w-full bg-black/75 backdrop-filter"
        ></div>
        <div
          id="modal-box"
          className="absolute left-1/2 top-1/2 z-50 flex hidden  min-w-[80vw] -translate-x-1/2 -translate-y-1/2 flex-col items-start gap-2 rounded-xl bg-slate-800/75 px-16 py-14 backdrop-filter sm:w-[385px] sm:min-w-[60vw] md:min-w-[50vw] lg:min-w-[50vw]"
        >
          <div
            id="modal-close-icon"
            className="absolute right-8 top-8 z-50 h-8 w-8 cursor-pointer"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-8 w-8 fill-slate-400"
              viewBox="0 0 24 24"
            >
              <path d="M12.0007 10.5865L16.9504 5.63672L18.3646 7.05093L13.4149 12.0007L18.3646 16.9504L16.9504 18.3646L12.0007 13.4149L7.05093 18.3646L5.63672 16.9504L10.5865 12.0007L5.63672 7.05093L7.05093 5.63672L12.0007 10.5865Z"></path>
            </svg>
          </div>
          <h2 className="label mb-4 mt-0 text-3xl font-semibold tracking-tight text-sky-200">
            Examples of commmon dream themes
          </h2>
          <div className="flex flex-wrap">
            <div className="px-2 py-2 md:w-1/2 xl:w-1/2">
              <div
                className="dream-example cursor-pointer rounded-lg border border-slate-600 p-6 transition duration-150 ease-out hover:border-indigo-200 hover:ease-in"
                onClick={() => {
                  const paragraphText =
                    "I dreamt a giant spider was chasing me."; // Replace with the actual text
                  onDreamExampleClick(paragraphText); // Call the callback function with the text
                }}
              >
                <h2 className="title-font mb-2 text-xl font-normal text-sky-100">
                  Being chased
                </h2>
                <p className="dream-description text-lg font-normal leading-relaxed tracking-normal text-slate-300">
                  I dreamt a giant spider was chasing me.
                </p>
              </div>
            </div>
            <div className="px-2 py-2 md:w-1/2 xl:w-1/2">
              <div
                className="dream-example cursor-pointer rounded-lg border border-slate-600 p-6 transition duration-150 ease-out hover:border-indigo-200 hover:ease-in"
                onClick={() => {
                  const paragraphText = "In my dream I was naked in public."; // Replace with the actual text
                  onDreamExampleClick(paragraphText); // Call the callback function with the text
                }}
              >
                <h2 className="title-font mb-2 text-xl font-normal text-sky-100">
                  Being naked
                </h2>
                <p className="dream-description text-lg font-normal leading-relaxed tracking-normal text-slate-300">
                  In my dream I was naked in public.
                </p>
              </div>
            </div>
            <div className="px-2 py-2 md:w-1/2 xl:w-1/2">
              <div
                className="dream-example cursor-pointer rounded-lg border border-slate-600 p-6 transition duration-150 ease-out hover:border-indigo-200 hover:ease-in"
                onClick={() => {
                  const paragraphText =
                    "I dreamt I was flying through the sky."; // Replace with the actual text
                  onDreamExampleClick(paragraphText); // Call the callback function with the text
                }}
              >
                <h2 className="title-font mb-2 text-xl font-normal text-sky-100">
                  Flying
                </h2>
                <p className="dream-description text-lg font-normal leading-relaxed tracking-normal text-slate-300">
                  I dreamt I was flying through the sky.
                </p>
              </div>
            </div>
            <div className="px-2 py-2 md:w-1/2 xl:w-1/2">
              <div
                className="dream-example cursor-pointer rounded-lg border border-slate-600 p-6 transition duration-150 ease-out hover:border-indigo-200 hover:ease-in"
                onClick={() => {
                  const paragraphText = "I had a dream where I was swimming."; // Replace with the actual text
                  onDreamExampleClick(paragraphText); // Call the callback function with the text
                }}
              >
                <h2 className="title-font mb-2 text-xl font-normal text-sky-100">
                  Water
                </h2>
                <p className="dream-description text-lg font-normal leading-relaxed tracking-normal text-slate-300">
                  I had a dream where I was swimming.
                </p>
              </div>
            </div>
            <div className="px-2 py-2 md:w-1/2 xl:w-1/2">
              <div
                className="dream-example cursor-pointer rounded-lg border border-slate-600 p-6 transition duration-150 ease-out hover:border-indigo-200 hover:ease-in"
                onClick={() => {
                  const paragraphText =
                    "I dreamt that my teeth were falling out."; // Replace with the actual text
                  onDreamExampleClick(paragraphText); // Call the callback function with the text
                }}
              >
                <h2 className="title-font mb-2 text-xl font-normal text-sky-100">
                  Losing teeth
                </h2>
                <p className="dream-description text-lg font-normal leading-relaxed tracking-normal text-slate-300">
                  I dreamt that my teeth were falling out.
                </p>
              </div>
            </div>
            <div className="px-2 py-2 md:w-1/2 xl:w-1/2">
              <div
                className="dream-example cursor-pointer rounded-lg border border-slate-600 p-6 transition duration-150 ease-out hover:border-indigo-200 hover:ease-in"
                onClick={() => {
                  const paragraphText = "I had a dream about death or dying."; // Replace with the actual text
                  onDreamExampleClick(paragraphText); // Call the callback function with the text
                }}
              >
                <h2 className="title-font mb-2 text-xl font-normal text-sky-100">
                  Dying
                </h2>
                <p className="dream-description text-lg font-normal leading-relaxed tracking-normal text-slate-300">
                  I had a dream about death or dying.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Modal;
